@import url('https://fonts.googleapis.com/css?family=Source+Code+Pro');

.parallax-container {
  position: relative;
  .parallax {
    height: 100vh;
    // background: url(/assets/xxx.jpeg);  // move to tsx file
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  
    .text {
      // margin-top: 40px;
      color: white;
  
      .title {
        font-family: 'Dancing Script', cursive;
        font-weight: 700;
        font-size: 80px;
        letter-spacing: 1px;
        margin-bottom: 40px;
        // Fade in down effect
        opacity: 0;
        transform: translateY(-15vh);
        animation: fadeInDown 1.5s ease forwards;
      }
      .sub-title {
        font-family: 'Raleway', 'Source Code Pro', monospace;
        font-weight: 500;
        font-size: 26px;
        opacity: 0;
        transform: translateY(-15vh);
        animation: fadeInDown 1.5s ease forwards;
      }
    }
  }
  .parallax::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6); // 添加褪色效果
    // z-index: -1;
  }
}


@keyframes fadeInDown {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}


