.price-marker {
  width: auto;
  height: auto;
  background: none;
  border: none;
  box-shadow: none;
}

.price-marker-content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  border-radius: 45px;
  background-color: #065A82;
  font-weight: bold;
  color: white;
  border: 2px solid white;
  font-size: 14px;
}
